import React, { useState, useEffect } from 'react';
import { MdAdd, MdDelete, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import {
	useCreateMutation,
	useDeleteMutation,
	useGetQuery,
	useUpdateMutation,
} from '../query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners'; // Import a loader component
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

const Chapter = () => {
	const user = useSelector((state) => state.auth.user);

	const [subjects, setSubjects] = useState([]); // To store subjects
	const [selectedSubject, setSelectedSubject] = useState(''); // To store selected subject

	const [newChapter, setNewChapter] = useState(''); // To store new chapter input
	const [chapters, setChapters] = useState([]); // To store new chapters for the selected subject
	const [subjectChapters, setSubjectChapters] = useState([]); // To store chapters for the selected subject

	const queryClient = useQueryClient();
	const { data, error, isLoading } = useGetQuery({
		queryKey: ['Subject'],
		url: `subject/instructor/${user._id}`,
	});

	const {
		data: chapterData,
		error: chapterError,
		isLoading: chapterLoading,
	} = useGetQuery({
		queryKey: ['Chapter'],
		url: `chapter/subject/${selectedSubject}`,
		enabled: !!selectedSubject,
	});
	const createMutation = useCreateMutation();
	const deleteMutation = useDeleteMutation();
	const updateMutation = useUpdateMutation();

	useEffect(() => {
		if (data) {
			setSubjects(data?.subjects);
		}
	}, [data]);

	useEffect(() => {
		if (chapterData) {
			setSubjectChapters(chapterData?.chapters);
		}
	}, [chapterData]);

	// Handle subject change
	const handleSubjectChange = (e) => {
		setSelectedSubject(e.target.value);
		// Fetch chapters for the selected subject here
		// Assuming chapters are part of the subjects data
		const subject = data.subjects.find((sub) => sub._id === e.target.value);
		setSubjectChapters(subject ? subject.chapters : []);
	};

	// Handle chapter input change
	const handleChapterChange = (e) => {
		console.log(e.target.value);
		setNewChapter(e.target.value);
	};

	// Add new chapter to the list
	const addChapter = () => {
		const trimmedChapter = newChapter.trim();

		if (trimmedChapter !== '') {
			// Check if the chapter is already in the local `chapters` list
			if (chapters.includes(trimmedChapter)) {
				toast.error('You have already added this chapter.');
				return;
			}

			// Check if the chapter already exists in `subjectChapters`
			const chapterExists = subjectChapters.some(
				(chapter) => chapter.title.toLowerCase() === trimmedChapter.toLowerCase()
			);

			if (chapterExists) {
				toast.error('This chapter already exists.');
				return;
			}

			// Add the new chapter if no duplicates were found
			setChapters([...chapters, trimmedChapter]);
			setNewChapter('');
		}
	};

	// Remove chapter from the list
	const removeChapter = (index) => {
		setChapters(chapters.filter((_, i) => i !== index));
	};

	const handleSubmit = () => {
		console.log(selectedSubject, chapters);
		if (selectedSubject && chapters.length > 0) {
			createMutation.mutate(
				{
					data: { subjectId: selectedSubject, chapters },
					url: 'chapter',
					queryKey: 'Chapter',
				},
				{
					onSuccess: async () => {
						// Invalidate the query for subjects so it refetches updated data
						await queryClient.invalidateQueries('Subject');
						setChapters([]);
					},
				}
			);
		} else {
			toast.error('Please select a subject and add chapters.');
		}
	};

	const deleteChapter = (index) => {
		deleteMutation.mutate(
			{
				id: chapterData.chapters[index]._id,
				url: 'chapter',
				queryKey: 'Chapter',
			},
			{
				onSuccess: () => {
					// Remove the chapter from local state
					queryClient.invalidateQueries('Subject');
					// toast.success('Chapter deleted successfully!');
					// Clear selected subject chapters after successful deletion
					setSubjectChapters(subjectChapters.filter((_, i) => i !== index));
				},
			}
		);
	};

	const updateChapter = (chapterId, updatedData) => {
		updateMutation.mutate({
			id: chapterId,
			data: updatedData,
			url: 'chapter',
			queryKey: 'Chapter',
		});
	};

	const toggleActiveStatus = (id) => {
		// Find the subject and toggle its isActive status
		const updatedChapters = subjectChapters.map((chapter) =>
			chapter._id === id ? { ...chapter, active: !chapter.active } : chapter
		);
		setSubjectChapters(updatedChapters);

		// Call updateSubject with the new active status
		const updatedChapter = updatedChapters.find(
			(subject) => subject._id === id
		);
		console.log(updatedChapter);
		updateChapter(id, {
			title: updatedChapter.title,
			active: updatedChapter.active,
		}); // Pass the new active status
	};

	return (
		<div className="p-6 space-y-6 bg-white rounded-lg shadow-md max-w-2xl mx-auto">
			<ToastContainer />
			<h2 className="text-2xl font-semibold mb-4 w-full text-center">
				Manage Chapters
			</h2>

			<div className="mb-4">
				<label className="block text-gray-700 mb-2">Select Subject:</label>
				<select
					value={selectedSubject}
					onChange={handleSubjectChange}
					className="border rounded-lg p-2 w-full"
				>
					<option value="" disabled>
						Select a subject
					</option>
					{subjects
						.filter((subject) => subject.active)
						.map((subject) => (
							<option key={subject._id} value={subject._id}>
								{subject.title}
							</option>
						))}
				</select>
			</div>

			{chapterLoading ? (
				<div className="flex justify-center items-center h-48">
					<ClipLoader size={50} color={'#4A90E2'} loading={chapterLoading} />
				</div>
			) : !selectedSubject ? (
				<p className="text-gray-500">Please select a subject</p>
			) : (
				<div className="mb-4">
					<h3 className="text-xl font-semibold mb-2">
						Chapters Already Present in the Subject:
					</h3>
					{subjectChapters?.length === 0 ? (
						<li className="text-gray-500 ml-5">
							Start adding your first chapter
						</li>
					) : (
						<ul className="list-disc">
							{subjectChapters?.map((chapter, index) => (
								<li
									key={index}
									className="flex items-center mb-2 p-4 px-8 border rounded shadow-sm"
								>
									<span className="flex-grow">{chapter.title}</span>
									<div className="flex items-center justify-center space-x-4">
										{chapter.active ? (
											<MdVisibility
												onClick={() => toggleActiveStatus(chapter._id)}
												className="text-green-500 text-xl cursor-pointer"
											/>
										) : (
											<MdVisibilityOff
												onClick={() => toggleActiveStatus(chapter._id)}
												className="text-gray-500 text-xl cursor-pointer"
											/>
										)}
										<button
											onClick={() => deleteChapter(index)}
											className="text-red-500"
										>
											<MdDelete size={20} />
										</button>
									</div>
								</li>
							))}
						</ul>
					)}
				</div>
			)}

			<div className="mb-4">
				<label className="block text-gray-700 mb-2">Add Chapter:</label>
				<div className="flex">
					<input
						type="text"
						value={newChapter}
						onChange={handleChapterChange}
						placeholder="Chapter name"
						className="border rounded-lg p-2 w-full mr-2"
					/>
					<button
						onClick={addChapter}
						className="bg-blue-500 text-white rounded-lg px-4 py-2"
					>
						<MdAdd size={20} />
					</button>
				</div>
			</div>

			<div className="mb-4">
				<h3 className="text-xl font-semibold mb-2">New Chapters List:</h3>
				{chapters.length > 0 ? (
					<ul className="list-disc">
						{chapters.map((chapter, index) => (
							<li
								key={index}
								className="flex items-center mb-2 p-4 px-8 border rounded shadow-sm"
							>
								<span className="flex-grow">{chapter}</span>

								<button
									onClick={() => removeChapter(index)}
									className="text-red-500"
								>
									<MdDelete size={20} />
								</button>
							</li>
						))}
					</ul>
				) : (
					<p className="text-gray-500">Start adding 1st</p>
				)}
			</div>

			<button
				onClick={handleSubmit}
				className="bg-green-500 text-white rounded-lg px-4 py-2 mt-4"
			>
				Submit
			</button>

			{isLoading && <ClipLoader color="#000000" />}
			{error && <p className="text-red-500">Error: {error.message}</p>}
		</div>
	);
};

export default Chapter;
