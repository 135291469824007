import React, { useEffect, useRef, useState } from 'react';
import { useGetQuery } from '../../query';
import { useSelector } from 'react-redux';
import { translations } from '../../lib/translations';

function CourseFieldsForm({
	subjects,
	setSubjects,
	setChapters,
	setSelectedSubject,
	setSelectedChapter,
	chapters,
	thumbnailFile,
	thumbnailSrc,
	courseTitle,
	description,
	setCourseTitle,
	setDescription,
	setThumbnailFile,
	setThumbnailSrc,
	setSavedTimestamps,
	videoFile,
	handleFileChange,
	videoRef,
	selectedLanguage,
}) {
	const user = useSelector((state) => state.auth.user);

	const [selectedSubjectValue, setSelectedSubjectValue] = useState('');
	const [selectedChapterValue, setSelectedChapterValue] = useState('');
	const [titleWordCount, setTitleWordCount] = useState(0);
	const [descriptionWordCount, setDescriptionWordCount] = useState(0);
	const [titleError, setTitleError] = useState('');
	const [descriptionError, setDescriptionError] = useState('');

	const fileInputRef = useRef(null);

	// Fetch the subjects and chapters from the API
	const { data, error, isLoading } = useGetQuery({
		queryKey: ['Subject'],
		url: `subject/instructor/${user._id}`, // Replace with your actual API URL
	});

	useEffect(() => {
		if (data) {
			setSubjects(data.subjects); // Set the subjects when data is fetched
		}
	}, [data]);

	const handleTitleChange = (e) => {
		const value = e.target.value;
		const wordCount = value.trim().split(/\s+/).length;
		setTitleWordCount(wordCount);
		if (wordCount > 12) {
			setTitleError('Course title should not exceed 12 words.');
		} else {
			setTitleError('');
		}
		setCourseTitle(value);
	};

	// Word count validation for description
	const handleDescriptionChange = (e) => {
		const value = e.target.value;
		const wordCount = value.trim().split(/\s+/).length;
		setDescriptionWordCount(wordCount);
		if (wordCount > 75) {
			setDescriptionError('Description should not exceed 75 words.');
		} else {
			setDescriptionError('');
		}
		setDescription(value);
	};

	// Thumbnail preview
	const handleThumbnailChange = (file) => {
		if (file) {
			const maxSizeInBytes = 3 * 1024 * 1024; // 3 MB
			if (file.size > maxSizeInBytes) {
				alert('Please upload an image smaller than 3 MB.');
				setThumbnailFile(null);
				setThumbnailSrc(null);
				fileInputRef.current.value = null;
				return;
			}

			const img = new Image();
			const reader = new FileReader();

			reader.onloadend = () => {
				img.src = reader.result;
			};

			img.onload = () => {
				if (img.width > img.height) {
					// Landscape image
					setThumbnailFile(file);
					setThumbnailSrc(img.src);
				} else {
					// Portrait image
					alert('Please upload a landscape image.');
					setThumbnailFile(null);
					setThumbnailSrc(null);
					fileInputRef.current.value = null;
				}
			};

			reader.readAsDataURL(file);
		}
	};

	const handleRemoveThumbnail = (e) => {
		setThumbnailFile(null);
		setThumbnailSrc(null);
		fileInputRef.current.value = null;
	};

	// Function to handle subject selection
	const handleSubjectChange = (e) => {
		const selectedSubjectId = e.target.value;
		setSelectedSubjectValue(selectedSubjectId);

		const foundSubject = subjects.find(
			(subject) => subject._id === selectedSubjectId
		);
		setSelectedSubject(foundSubject || null);
		setSelectedChapter(null);
		setSelectedChapterValue('');
		setChapters(foundSubject ? foundSubject.chapters : []);
		setSavedTimestamps([]);
	};

	const handleChapterChange = (e) => {
		const selectedChapterId = e.target.value;
		setSelectedChapterValue(selectedChapterId);

		const foundChapter = chapters.find(
			(chapter) => chapter._id === selectedChapterId
		);
		setSelectedChapter(foundChapter || null);
	};

	const handleFileDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const files = e.dataTransfer.files;
		if (files.length > 0) {
			handleThumbnailChange(files[0]);
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<>
			{/* Subject Dropdown */}
			<div>
				<label className="block text-gray-700">
					{translations[selectedLanguage]?.subject}
				</label>
				<select
					className="w-full p-2 border rounded mt-1"
					value={selectedSubjectValue}
					onChange={handleSubjectChange}
				>
					<option value="">
						{translations[selectedLanguage]?.selectSubject}
					</option>
					{subjects
						.filter((subject) => subject.active)
						.map((subject) => (
							<option key={subject._id} value={subject._id}>
								{subject.title}
							</option>
						))}
				</select>
			</div>

			{/* Chapter Dropdown */}
			<div>
				<label className="block text-gray-700">
					{translations[selectedLanguage]?.chapter}
				</label>
				<select
					className="w-full p-2 border rounded mt-1"
					value={selectedChapterValue}
					onChange={handleChapterChange}
					disabled={!selectedSubjectValue}
				>
					<option value="">
						{translations[selectedLanguage]?.selectChapter}
					</option>
					{chapters
						.filter((chapter) => chapter.active)
						.map((chapter, index) => (
							<option key={index} value={chapter._id}>
								{chapter.title}
							</option>
						))}
				</select>
			</div>

			{/* Course Title */}
			<div>
				<label className="block text-gray-700">
					{translations[selectedLanguage]?.courseTitle}
				</label>
				<input
					type="text"
					className="w-full p-2 border rounded mt-1"
					value={courseTitle}
					onChange={handleTitleChange}
					placeholder="Enter course title"
					maxLength={100}
				/>
				<p className="text-gray-500 text-xs w-full text-right">
					{titleWordCount}/12 words
				</p>
				{titleError && (
					<p className="text-red-500">
						{translations[selectedLanguage]?.titleError}
					</p>
				)}
			</div>

			{/* Description */}
			<div>
				<label className="block text-gray-700">
					{translations[selectedLanguage]?.description}
				</label>
				<textarea
					className="w-full p-2 border rounded mt-1"
					value={description}
					onChange={handleDescriptionChange}
					placeholder="Enter description"
					rows={6}
				/>
				<p className="text-gray-500 text-xs w-full text-right">
					{descriptionWordCount}/75{' '}
					{translations[selectedLanguage]?.words}
				</p>
				{descriptionError && (
					<p className="text-red-500">
						{translations[selectedLanguage]?.descriptionError}
					</p>
				)}
			</div>

			{/* Upload Thumbnail */}
			<div>
				<label className="block text-gray-700">
					{translations[selectedLanguage]?.uploadThumbnail.description}{' '}
					{translations[selectedLanguage]?.uploadThumbnail.sizeLimit}
				</label>
				<div
					className="border-2 border-dashed border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center cursor-pointer hover:border-blue-500"
					onClick={() => fileInputRef.current.click()}
					onDrop={handleFileDrop}
					onDragOver={handleDragOver}
				>
					<input
						type="file"
						accept="image/*"
						className="hidden"
						onChange={(e) => handleThumbnailChange(e.target.files[0])}
						ref={fileInputRef}
					/>
					<p className="text-gray-500 mb-2">
						{translations[selectedLanguage]?.dragAndDropImage}
					</p>
					{thumbnailSrc ? (
						<img
							src={thumbnailSrc}
							alt="Thumbnail preview"
							className="w-full h-auto rounded-lg mb-2"
						/>
					) : (
						<p className="text-gray-400">
							{translations[selectedLanguage]?.noFileSelected}
						</p>
					)}
				</div>
				{thumbnailSrc && (
					<button
						onClick={handleRemoveThumbnail}
						className="mt-1 bg-red-500 text-white p-2 rounded hover:bg-red-600 transition"
					>
						{translations[selectedLanguage]?.remove}
					</button>
				)}
			</div>

			{/* Vieo Upload */}
			<div className="flex flex-col gap-y-1">
				{!videoFile && (
					<label className="text-gray-700" htmlFor="video">
						{translations[selectedLanguage]?.chooseVideo}
					</label>
				)}
				<input
					type="file"
					id="video"
					accept="video/*"
					onChange={handleFileChange}
					ref={videoRef}
					className="w-full p-2 border rounded"
				/>
			</div>
		</>
	);
}

export default CourseFieldsForm;
